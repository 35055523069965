<template>
  <router-view/>
</template>

<script></script>

<style lang="scss">
@import "assets/general";

body {
  margin: 0;
  padding: 0;

  @include desktop {
    overflow: hidden;
  }
  @include mobile {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/******************************************/
/**  SCROLLBAR                           **/
/******************************************/
#app {
  height: 100%;
  position: relative;

  @include desktop {
    overflow: auto;

    ::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    ::-webkit-scrollbar-track {
      background: #ffffff;
      border-radius: 0px;
      box-shadow: inset 7px 10px 12px #d0d0d0;
    }

    ::-webkit-scrollbar-thumb {
      background: #37343361;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover{
      background: #4a6da7;
    }
  }

  @include mobile {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    ::-webkit-scrollbar {
      display: none;
    }
  }
}



/******************************************/
/**  CALENDAR                            **/
/******************************************/

/* Removes the clear button from date inputs */
input[type="time"]::-webkit-clear-button, input[type="date"]::-webkit-clear-button {
  display: none;
}

/* Removes the spin button */
input[type="time"]::-webkit-inner-spin-button, input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

/* Always display the drop down caret */
input[type="time"]::-webkit-calendar-picker-indicator, input[type="date"]::-webkit-calendar-picker-indicator {
  color: #2c3e50;
}

/* A few custom styles for date inputs */
input[type="date"] {
  @include hide-outline;
  width: 150px;
}

/* A few custom styles for date inputs */
input[type="time"] {
  @include hide-outline;
  width: 85px;
}

input[type="date"], input[type="time"] {
  appearance: none;
  -webkit-appearance: none;
  color: #95a5a6;
  font-size: 17px;
  //border:1px solid #ecf0f1;
  border: none;
  background: transparent;//#ecf0f1;
  padding: 5px;
  display: inline-block !important;
  visibility: visible !important;
  margin: 0;
  @include font-open-sans;
  font-weight: 300;

  &:hover[disabled="false"] {
    cursor: pointer;
  }
}

input[type="date"], input[type="time"], focus {
  color: #95a5a6;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

/******************************************/
/**  RANGE                               **/
/******************************************/

input[type=range] {
  height: 20px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  &:focus {
    outline: none;
    &::-webkit-slider-runnable-track {
      background: #003C53;
    }
    &::-ms-fill-lower {
      background: #003C53;
    }
    &::-ms-fill-upper {
      background: #003C53;
    }
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: 0 0 0 #000000;
    background: #003C53;
    border-radius: 0;
    border: 1px solid #000108;
  }
  &::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 3px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7.5px;
  }
  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: 0 0 0 #000000;
    background: #003C53;
    border-radius: 0;
    border: 1px solid #000108;
  }
  &::-ms-thumb, &::-moz-range-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 3px;
    background: #FFFFFF;
    cursor: pointer;
  }
  &::-ms-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: #003C53;
    border: 1px solid #000108;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  &::-ms-fill-upper {
    background: #003C53;
    border: 1px solid #000108;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
}


br {
  clear: both;
}
</style>